// Core
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

// Instruments
import styles from './markets-slider.module.scss';
import BurgerSlider from '../burger-slider/burger-slider';
import CarouselBlock from '../carousel/carousel';
import VerticalLines from '../vertical-lines/ vertical-lines';

const MarketsSlider = ({
  activeSection,
  index,
  data,
  isMobile,
}) => {
  const [lineHeight, setLineHeight] = useState(0);
  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 40px)` : 0;
    setLineHeight(topPosition);
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    window.addEventListener('scroll', handleWindowSizeChange);
    document.querySelector('#header').classList.add('page-market-slider');
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);

  const dataActive = `active_${activeSection}`;
  const renderSection = () => (
    <section data-active={dataActive} className={`current-${index} ${styles.marketsSliderSection} ${styles.sections} v5 markets-slider`}>
      <VerticalLines />
      <BurgerSlider />
      <CarouselBlock
        index={index}
      >
        {data.map((item) => (
          <div className={styles.slide}>
            <div className={`${styles.animatedTextContainerBox}`}>
              <div className={`${styles.animatedTextContainer}`}>
                <h3 className={`animatedSliderText ${styles.h3}`}>{item.node.acf.slide_title}</h3>
                <div className={styles.divider_p_decription} />
                <p className={`animatedSliderText ${styles.p_decription}`}>{item.node.acf.slide_text_paragraph_1}</p>
                <div className={styles.divider_p_decription_inner} />
                <p className={`animatedSliderText ${styles.p_decription}`}>{item.node.acf.slide_text_paragraph_2}</p>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <p className={`animatedSliderText ${styles.p_list} ${styles.p_list_second_font} ${styles.p_list_second_font_medium}`}>{item.node.acf.slide_title}</p>
              </div>
            </div>
            <img src={item.node.acf.slide_image.url} className={styles.image} alt="" />
          </div>
        ))}
      </CarouselBlock>
    </section>
  );

  return (
    isMobile
      ? (
        <ScrollAnimation animateOnce initiallyVisible animateIn={`${styles.animateIn} animate-in`}>
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};

MarketsSlider.propTypes = {
  activeSection: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MarketsSlider;
