/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import styles from './burger-slider.module.scss';

const BurgerSlider = () => {
  const [showBurger, setShowBurger] = useState(false);
  useEffect(() => {
    setShowBurger(true);
  });

  const goBack = () => {
    setShowBurger(false);
    setTimeout(() => {
      window.history.back();
    }, 400);
  };
  return (
    <div onClick={goBack} className={`${styles.burger} ${showBurger ? styles.showMenu : ''}`} />
  );
};

export default BurgerSlider;
