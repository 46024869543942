/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import './carousel.module.scss';
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './carousel-reset.scss';

const CarouselBlock = ({
  children, getCurrentNumber, showDots, index,
}) => {
  useEffect(() => {
    const firstSlide = document.querySelector(`section.current-${index} .slick-slide.slick-active.slick-current`);
    firstSlide.dataset.active = 'active';
    firstSlide.dataset.side = 'next';
  }, []);
  const sliderRef = useRef(0);
  const prevSlideRef = useRef(0);
  const nextSlideRef = useRef(0);
  const isFirstSlide = (current) => {
    if (current === 0) {
      prevSlideRef.current.classList.add('slick-disabled');
    } else {
      prevSlideRef.current.classList.remove('slick-disabled');
    }
  };
  const isLastSlide = (current) => {
    const sliderCount = +document.querySelector(`section.current-${index} .slick-track`).children.length - 1;
    if (sliderCount === current) {
      nextSlideRef.current.classList.add('slick-disabled');
    } else {
      nextSlideRef.current.classList.remove('slick-disabled');
    }
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          ref={prevSlideRef}
          className="slick-arrow slick-prev slick-disabled"
          onClick={prevSlide}
        />
        {showDots
          ? (
            <ul>
              {dots}
            </ul>
          ) : null}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          ref={nextSlideRef}
          type="button"
          className="slick-arrow slick-next"
          onClick={nextSlide}
        />
      </div>
    ),
    beforeChange: (current, next) => {
      isFirstSlide(next);
      isLastSlide(next);
      if (current !== next) {
        document.querySelectorAll(`section.current-${index} .slick-slide`).forEach((el) => {
          const localEl = el;
          localEl.dataset.side = '';
          localEl.dataset.active = '';
          localEl.dataset.previous = '';
        });
        const prevSlides = document.querySelectorAll(`section.current-${index} .slick-slide[data-index="${current}"]`);
        const currentSlides = document.querySelectorAll(`section.current-${index} .slick-slide[data-index="${next}"]`);
        prevSlides.forEach((el) => {
          const localEl = el;
          localEl.dataset.previous = 'previous';
        });
        currentSlides.forEach((el) => {
          const localEl = el;
          localEl.dataset.active = 'active';
        });
        if (current < next) {
          prevSlides.forEach((el) => {
            const localEl = el;
            localEl.dataset.side = 'next';
          });
          currentSlides.forEach((el) => {
            const localEl = el;
            localEl.dataset.side = 'next';
          });
        } else {
          prevSlides.forEach((el) => {
            const localEl = el;
            localEl.dataset.side = 'prev';
          });
          currentSlides.forEach((el) => {
            const localEl = el;
            localEl.dataset.side = 'prev';
          });
        }
        setTimeout(() => {
          getCurrentNumber(next + 1);
        }, 1000);
      }
    },
    afterChange: (current) => {
      getCurrentNumber(current + 1);
    },
  };
  return (
    <Slider ref={sliderRef} {...settings}>
      {children}
    </Slider>
  );
};

CarouselBlock.propTypes = {
  children: PropTypes.node.isRequired,
  getCurrentNumber: PropTypes.func,
  showDots: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
};

CarouselBlock.defaultProps = {
  showDots: true,
  isMobile: false,
  getCurrentNumber: () => {},
};

export default CarouselBlock;
